var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": !_vm.right || !_vm.bottom || !_vm.left,
      "right": _vm.right,
      "top": _vm.top,
      "left": _vm.left,
      "color": _vm.$vuetify.theme.dark ? '' : 'color',
      "disabled": false,
      "transition": _vm.transition,
      "max-width": _vm.MaxWidth
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm.custom ? _c('span', [_vm._t("default")], 2) : _c('span', [_vm.displayText ? _c('span', [_vm._v(" " + _vm._s(_vm.displayText) + " ")]) : _c('span', [_vm.dots ? _c('code', {
          staticClass: "max-text"
        }, [_c('span', [_vm._v("..")])]) : _c('v-icon', {
          attrs: {
            "small": !_vm.large,
            "color": _vm.IconColor
          },
          domProps: {
            "textContent": _vm._s(_vm.iconText)
          }
        })], 1)])])];
      }
    }], null, true)
  }, [_vm._t("message"), _vm.VHtml ? _c('span', {
    class: _vm.textClass,
    domProps: {
      "innerHTML": _vm._s(_vm.VHtml)
    }
  }) : _vm._e(), _vm.text ? _c('span', {
    class: _vm.textClass,
    staticStyle: {
      "white-space": "pre-line"
    },
    domProps: {
      "textContent": _vm._s(_vm.text)
    }
  }) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }